import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import MarkdownWrapper from '../../../../components/MarkdownWrapper';
import Layout from '../../../../components/Layout';
export const _frontmatter = {
  "title": "AWS EC2",
  "path": "/knowledge/tech/aws",
  "date": "2023-01-02T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout title={props.pageContext.frontmatter.title} location={props.path} mdxType="Layout">
      <MarkdownWrapper mdxType="MarkdownWrapper">
        <h1 {...{
          "id": "aws--ec2",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h1" {...{
            "href": "#aws--ec2",
            "aria-label": "aws  ec2 permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`AWS — EC2`}</h1>
        <p>{`EC2 helps engineers create a customizable server in which they can customize its OS, resources, and firewall.`}</p>
        <h2 {...{
          "id": "steps-taken-to-create-an-ec2-instance",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#steps-taken-to-create-an-ec2-instance",
            "aria-label": "steps taken to create an ec2 instance permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Steps taken to create an EC2 instance`}</h2>
        <p>{`The steps here are what I chose my instance will use, but they don't necessarily mean this is what your instance should also use.`}</p>
        <h3 {...{
          "id": "name-and-tags",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#name-and-tags",
            "aria-label": "name and tags permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Name and Tags`}</h3>
        <p>{`Where you choose the Instance name or add additional tags.`}</p>
        <p>{`The name I picked is `}<inlineCode parentName="p">{`Flacial Links Server`}</inlineCode></p>
        <h3 {...{
          "id": "application-and-os-images",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#application-and-os-images",
            "aria-label": "application and os images permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Application and OS Images`}</h3>
        <p>{`I chose Ubuntu x86 because I'm familiar with it, and it uses fewer resources compared to Windows.`}</p>
        <h3 {...{
          "id": "instance-type",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#instance-type",
            "aria-label": "instance type permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Instance type`}</h3>
        <p>{`In this section, you choose the instance type or basically what CPU/Memory does the instance use.`}</p>
        <p>{`The instance varies from 2 vCPU/1 GiB Memory to 96 vCPU/764 GiB Memory.`}</p>
        <p>{`I picked t3.micro or 2 vCPU/1 GiB Memory because it's eligible to free tier.`}</p>
        <h3 {...{
          "id": "key-pair",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#key-pair",
            "aria-label": "key pair permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Key pair`}</h3>
        <p>{`Generate an SSH key that'll be used to securely connect to the instance. Once you pick the algorithm and key name, you'll download a file that has the credentials.`}</p>
        <h3 {...{
          "id": "network-settings",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#network-settings",
            "aria-label": "network settings permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Network settings`}</h3>
        <p>{`Here you can configure the security group (who can communicate with the instance and who it can communicate with), SSH traffic (connecting to the instance), HTTPs, and HTTP.`}</p>
        <p>{`I choose to enable HTTPs and HTTP traffic, and only allow my IP to connect to the instance.`}</p>
        <h3 {...{
          "id": "configure-storage",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#configure-storage",
            "aria-label": "configure storage permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Configure storage`}</h3>
        <p>{`Here we configure the storage the instance can have. We can choose up to 30 GB of storage in the free tier, and this is what I picked.`}</p>
        <h5 {...{
          "id": "additional-details",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h5" {...{
            "href": "#additional-details",
            "aria-label": "additional details permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Additional details`}</h5>
        <p>{`Advanced stuff that I didn't touch.`}</p>
        <h2 {...{
          "id": "connecting-to-the-instance",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#connecting-to-the-instance",
            "aria-label": "connecting to the instance permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Connecting to the instance`}</h2>
        <p>{`After creating the instance, this window will show and follow the guide named "Connect to your instance"`}</p>
      </MarkdownWrapper>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      